html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #318cb6;
}

.linkcolor {
  color: #318cb6;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#home {
  background-image: linear-gradient(white, #eef5ff);
}

.jumbotron {
  background-color: #eef5ff;
}

.card-header {
  background-image: linear-gradient(white, #eef5ff);
}

.card-body {
  padding-left: 10px;
  padding-right: 10px;
}

.PlanCardBody {
  min-width: 300px;
  min-height: 200px;
}

.SecondaryHeader {
  font-family: Tahoma, Geneva, Sans-Serif;
  font-weight: 900; 
  color: #b0b8bb;
  text-shadow: 1px 1px 1px #999999;
}

.PrimaryHeader {
  font-weight: 900; 
  font-family: 'Roboto Slab', Serifa, Georgia, sans-serif;
}

@media(min-width: 768px) {
  .CaseTabs {
    position: absolute;
    right: 0px;
  }
}
